import React, { useRef, useState, useEffect } from "react"
import { Container, Row, Col, Dropdown, Form } from "react-bootstrap"
import "./Banner.scss"
import loadable from "@loadable/component";
// import BannerSearch from "./BannerSearchForm"
// import BannerSearchOverlay from "./BannerSearchOverlay"
import GenerateLink from "../common/site/GetLink"
import parse from "html-react-parser"
import SiteBreadcrumbs from "../BreadCrumbs/site-breadcrumbs"
import axios from "axios"
import wordsToNumbers from "words-to-numbers"
import { scrollToElement } from "../common/site/functions"
// import GetGGFXImage from "../../components/common/site/get-ggfx-image";
import ReactTextTransition, { presets } from "react-text-transition";
import { Helmet } from "react-helmet"
import darkBrandLogo02 from "../../images/logo-dark-tab.svg";
import bannerMobile from "../../images/bg/2021_Agency_Winner_Main_Image_mobile.webp";
import bannerDesktop from "../../images/bg/2021_Agency_Winner_Main_Image_desktop.webp";
import { isIOS, isMobile } from "react-device-detect";

import ElfsightWidget from "../Reviews/Elfsight/ElfsightWidget";

const BannerSearch = loadable(() => import("./BannerSearchForm"));
const BannerSearchOverlay = loadable(() => import("./BannerSearchOverlay"));


const Banner = (props) => {
  const [minsqftVal, setMinSqftVal] = useState('');
  const [maxsqftVal, setMaxSqftVal] = useState('');
  const [submitSearch, setsubmitSearch] = useState('');
  const [furnishval, setFurnishVal] = useState('');
  const [sqftVal, setSqftVal] = useState('');
  const [amenities, setAmenities] = useState('');
  const [parkingval, setParkingVal] = useState('');
  const [viewingval, setViewingVal] = useState('');
  const [testimonials, setTestimonials] = useState([])
  const [countLoop, setCountLoop] = useState(0);
  const [callReviewApi, setCallReviewApi ] = useState(true);
  const getitems = async url => {
    setCallReviewApi(false)
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        }
      }) // could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  
    useEffect(() => {
   
    if (props.multi_banner_title?.length > 0) {
      const interval = setInterval(() => {
        setCountLoop((countLoop) => {
          if (props.multi_banner_title?.length === (countLoop + 1)) {
            return 0
          } else {
            return countLoop + 1
          }
        });
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [])

  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

  }, [])
  if(renderComponent && callReviewApi){
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url);
  }

  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })

  var rating_avg = rating_count / testimonial_count

  let processedImages = JSON.stringify({});
  var processedImage = ""
  // if (props?.imagetransforms?.Banner_Overlay_Bg_Transforms) {
  //   processedImages = JSON.parse(props.imagetransforms.Banner_Overlay_Bg_Transforms);
  //   var processedObj = Object.values(processedImages)
  //   if(processedObj.length != 0){
  //     if(isMobile){
  //       processedImage = processedObj[0]['webp']['376x639'] || ""
  //     }else{
  //       processedImage = processedObj[0]['webp']['1800x1061'] || ""
  //     }
  //   }
  // } 
  if(props.overlaybanner) {
      processedImage = props.overlaybanner && props.overlaybanner.url
  }
  return (
    
    <React.Fragment>
      <Helmet>
    <script type="application/ld+json">{`{
         
        "@context": "http://schema.org",
        "@type": "Product",
        "name" : "${props?.page?.Meta_Title}",
        "description" : "${props?.page?.Meta_Description}",
        "brand" : {
          "@type" : "Brand",
          "name" : "Mccone Properties",
          "image" : "${darkBrandLogo02}"
      },
    "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "212",
            "bestRating": "5"
        }     
    }`}
     </script>
     </Helmet>
      <div className="home-filter-overlay" />
     

      {/* <ImageModule classNames="banner_bg" ImageSrc={GQLPage.Banner_Image} imagetransforms={processedImages} renderer="bgImg" imagename="articles.Banner_Overlay_Bg.bannerimgoverlay" strapi_id={GQLPage.id} /> */}
      <section
        className="banner"
        style={{
          backgroundImage: `url(${renderComponent ? processedImage : ""})`,
        }}
      >
      
       {/* <section
        className="banner"
        style={{
          backgroundImage: `url(${isMobile == true ? bannerMobile : bannerDesktop })`,
        }}
      > */}
       {/* <GetGGFXImage classNames="banner" renderer="bgImg" imagename={"articles.Banner_Overlay_Bg.bannerimgoverlay"} imagesources={props.overlaybanner} fallbackalt={props.title} imagetransformresult={processedImages} id={props.id} />                   */}
      {/* <section
        className="banner"
        style={{
          backgroundImage: `url(${isMobile == true ? "https://ggfx-mccone.s3.eu-west-2.amazonaws.com/x.prod/376x639/2021_Agency_Winner_Main_Image_7bac468a9b.webp" : "https://ggfx-mccone.s3.eu-west-2.amazonaws.com/x.prod/1800x1061/2021_Agency_Winner_Main_Image_7bac468a9b.webp" })`,
        }}
      > */}
        <div className="banner-overlay"></div>
        <Container>
          <Row>
            <Col md-12>
              {props.pageTitle != "Home" && <SiteBreadcrumbs />}
              <div className="banner-content ">

                {props.multi_banner_title?.length > 0 ?
                  <>
                  <h1 className="d-none">{props.multi_banner_title[0].Title}</h1>
                    <h2 className="heading">
                      <ReactTextTransition
                        children={props.multi_banner_title[countLoop].Title}
                        className="big"
                        delay={300}
                        direction="down"
                        inline
                      />
                    </h2>
                  </>

                  :
                  <h1>{props.title}</h1>
                }

                {props.pageTitle === "Home" && (
                  <div className="content-wrapper">
                    <div className="content">
                      <i className="icon-check"></i>
                      <span className="sm-text">Established in 2013 </span>
                    </div>
                    <div className="content">
                      <i className="icon-check"></i>
                      <h2 className="sm-text leading-estate-h2">
                        Leading Real Estate Agency
                      </h2>
                    </div>
                    <div className="content">
                      <i className="icon-check"></i>
                      <span className="sm-text">
                        {!isNaN(rating_avg) ? parseFloat(rating_avg).toFixed(1) : 
                        <div class="spinner-border spinner-border-sm ml-1" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div> }/5 from Google
                        Reviews
                      </span>
                    </div>
                  </div>
                )}
                {props.content && (
                  <div className="text-content">{parse(props.content)}</div>
                )}
                {props.search === true &&  (
                  <BannerSearch
                    viewingval={viewingval}
                    furnishval={furnishval}
                    sqftVal={sqftVal}
                    parkingval={parkingval}
                    submitSearch={submitSearch}
                    minsqftVal={minsqftVal}
                    maxsqftVal={maxsqftVal}
                  />
                )}

                {props.buttons && props.buttons.length > 0 && (
                  <div className="banner-ctas">
                    {props.buttons.map((item, index) => {
                      if (
                        item.CTA_Secondary_Link &&
                        item.CTA_Secondary_Link.indexOf("#") === 0
                      ) {
                        return (
                          <button
                            onClick={() => {
                              const element = document.getElementById(
                                item.CTA_Secondary_Link.replace("#", "")
                              )
                              if (element) {
                                scrollToElement(element)
                              }
                            }}
                            className={
                              index === 0 ? "btn" : "btn btn-secondary"
                            }
                            key={index}
                          >
                            <span>
                              <span>{item.CTA_Label}</span>
                              <i className="icon-right"></i>
                            </span>
                          </button>
                        )
                      }

                      return (
                        <GenerateLink
                          link={item.CTA_Link?.Primary_URL}
                          class={index === 0 ? "btn" : "btn btn-secondary"}
                        >
                          <span>
                            <span>{item.CTA_Label}</span>
                            <i className="icon-right"></i>
                          </span>
                        </GenerateLink>
                      )
                    })}
                  </div>
                )}

                {props.reviews === true && renderComponent == true  &&(
                  <div className="ratings">
                    <div className="rating-img">
                      <i className="google-reviews"></i>
                      <span className="reviews">  
                          <ElfsightWidget widgetID="2d63f214-8945-454a-b585-5d2bbe396d9b" />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {props.search === true && <BannerSearchOverlay submitSearch={setsubmitSearch} furnishval={setFurnishVal} sqftVal={setSqftVal} amenitiesvalues={setAmenities} parkingval={setParkingVal} viewingval={setViewingVal} minsqftVal={setMinSqftVal} maxsqftVal={setMaxSqftVal} />}
    </React.Fragment>
  )
}
export default Banner
