import React,{useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
// import PlayVideo from "../PlayVideo/PlayVideo";
import "./VideoBox.scss";
import GetGGFXImage from "../common/site/get-ggfx-image";
import loadable from "@loadable/component";
import bannerMobile from "../../images/bg/Call_800_Mc_Cone_mobile.webp";
import bannerDesktop from "../../images/bg/Call_800_Mc_Cone_desktop.webp";
import { isIOS, isMobile } from "react-device-detect";

const PlayVideo = loadable(() => import("../PlayVideo/PlayVideo"));

const VideoBox = (props) => {
  const [isPlay, setPlay] = useState(false);  
  let processedImages = JSON.stringify({});
  if (props?.imagetransforms?.Banner_Image_Transforms) {
   processedImages = props.imagetransforms.Banner_Image_Transforms;
  }
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

  }, [])
  const hasVideo = props.video && props.video.length > 0 ? true : false;

  return (
    <React.Fragment>
      <section className="video-box ">
        <Container>
          <Row>
            <Col md={12}>
              <div className={`video-img ${hasVideo && "zoom"}`}>
                {/* { props.localUrls == true ?
                  (<img src={isMobile == true ? bannerMobile : bannerDesktop }  />)
                  : ( */}
                  <picture>
                  <GetGGFXImage imagename={"articles.Banner_Image.bannerimg"} imagesources={props.bannerimage} fallbackalt={props.title} imagetransformresult={processedImages} id={props.id} loadLazy={false}/>                  
                  </picture>
                  {/* )
                }                            */}
                {hasVideo && <button className="video-wrap">
                  <i className="video-btn"></i>
                </button>}
                {hasVideo && <button onClick={(e) => { setPlay(true)}} className="video-wrap">
                  <i className="video-btn"></i>
                </button>}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {isPlay && renderComponent &&
        <PlayVideo isOpen={isPlay} videoId="V8ksSGxo6no"isCloseFunction={setPlay} videourl={props.video}/>
      }
    </React.Fragment>
  );
};
export default VideoBox;
